<template>
<div>
        <div  v-if="list.length>0" class="list">
        <div
            class="item"
            @mouseleave="handleLeave"
            @mouseenter="handleEnter(index)"
            v-for="(item,index) in list"
            :key="index"
            :style="{ 'margin-right': (index + 1) % 3 == 0 ? '0' : '' }"
            @click="handlePush(item.id)"
        >
      
            <div class="img">
                  <div class="title" v-if="index==imgIndex">
                        {{item.title}}
                   </div>
              <div class="img1" v-if="item.type_name=='古树复壮'">
                  <div class="front">
                      <img :src="'/gsmm/api/v1/'+item.recover_before" alt style="border-right:1px solid #000"  />
                      <div class="text">复壮修复前</div>
                 </div>
                   <div class="after">
                        <img :src="'/gsmm/api/v1/'+item.recover_behind" alt  style="border-left:1px solid #000" />
                         <div class="text">复壮修复后</div>
                   </div>
              </div>
                <div class="img2" v-if="item.type_name!=='古树复壮'">
                  <div class="front1">
                      <img :src="'/gsmm/api/v1/'+item.recover_before" alt   />
                      <!-- <div class="text">复壮修复前</div> -->
                 </div>
              </div>
            </div>
        </div>
    </div>
    <No v-if="list.length==0" />
</div>
</template>

<script>
import No from './no.vue'
export default {
    props: {
        list: Array,
        id:Number
    },
    data() {
        return {
            imgIndex: -1
        };
    },
    created() {

    },
    components:{
No
    },
    mounted() {

    },
    methods: {
        handleLeave() {//鼠标移出事件
             this.imgIndex=-1
        },
        handleEnter(index) {//鼠标移入事件
            this.imgIndex = index
            console.log(222)
        },
        		handlePush(id){
			this.$router.push({
				path:'/detail',
				query:{
					id:this.id,
                    successId:id
				}
			})
		}
    }
};
</script>

<style scoped lang='less'>
.list {
    width: 100%;
    // background: red;
    display: flex;
    flex-wrap: wrap;
    .item {
        width: 31%;
        height: 300px;
        background: #fff;
        margin-right: 3.5%;
        margin-top: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 20px;
        border:1px solid #eaeaea;
        box-shadow: 0 3px 6px rgba(0,0,0,0.2) ;
        .img {
            width: 100%;
            height: 100%;
            // display: flex;
            // align-items: center;
            // justify-content: center;
            position: relative;
            .img2{
                  width: 100%;
            height: 100%;
                .front1{
                    width:100%;
                    height:100%;
                    img{
                       width:100%;
                    height:100%;  
                    }
                }
            }
             .img1{
                 display: flex;
                 width: 100%;
            height: 100%;
                 .front{
                  width: 50%;
                height: 100%;
                position: relative;
                  img {
                width: 100%;
                height: 100%;
               }
               .text{
                   position: absolute;
                   height: 50px;
                   width: 100%;
                   text-align: center;
                   color: #fff;
                   left: 0;
                   bottom: 0;
               }
             }
              .after{
                  width: 50%;
                height: 100%;
                position: relative;
                  img {
                width: 100%;
                height: 100%;
            }
            .text{
                   position: absolute;
                   height: 50px;
                   width: 100%;
                   text-align: center;
                   color: #fff;
                   left: 0;
                   bottom: 0;
               }
             }
             }
            .title{
          position: absolute; 
          background: rgba(0,0 ,0 , 0.3);
          width:100%;
          height: 100%;  
          left: 0;
          top: 0;
          color: #fff;
          font-size: 14px;
         display: flex;
         align-items: center;
         justify-content: center;
         z-index: 1;
        }
        }
        
    }
}
@media (max-width:1919px){
    .list .item{
        height:230px;
    }
}
@media (max-width:1439px){
    .list .item{
        height:200px;
    }
}
</style>
